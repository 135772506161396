@font-face {
    font-family: ATypewriterForMe;
    src: url(fonts/ATypewriterForMe/ATypewriterForMe.ttf);
}

header {
    display: flex;
    z-index: 9999;
    flex-direction: column;
    align-items: center;
    font-family: ATypewriterForMe;
    font-weight: 500;
    position: absolute;
    top: 10em;
    width: 100%;
}

.site-menu {
    margin: 1.5em 0;
}

.site-menu ul > li{
    display: inline;
    margin: 2em;
}

.site-menu ul > a {
    text-decoration: none;
    list-style: none;
    color: white;
    text-transform: uppercase;
}

li:hover {
    border-bottom: 1px solid white;
}

li.active{border-bottom: 1px solid white;}

.site-menu ul {
    text-align: center;
    margin: 0;
    padding: 0;
}

.logo {
    align-items: center;
    height: 85px;
}

.scrolled {
    position: fixed;
    flex-direction: row;
    justify-content: space-between;
    top: 0;
    left: 0;
    background: rgba(0,0,0,1);
}

.scrolled .logo-container {
    transition: all 2s ease;
    height: 80px;
}

.scrolled .logo-container > .logo {
    height: 80px;
    padding: 20px;
    padding-left: 45px;
}

.scrolled .site-menu li {
    margin: 0.5em;
    transition: margin 0.5s ease;
    white-space: nowrap;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .logo {
        height: 35px;
    }

    .site-menu ul > li{
        display: none;
        margin: 0;
    }
}