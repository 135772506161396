@font-face {
    font-family: Homework;
    src: url(../common/fonts/Homework/Homework.otf);
}

.section {
    padding: 10px;
}


.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Homework;
    font-weight: 500;
    font-size: 4em;
    width: 100%;
    padding: 0.5em;
}

h6 {
    font-family: ATypewriterForMe;
}

.row{
    align-items: center;
}

.shadow {
    box-shadow: 0px 0px 10px #5d5d5d;
}

 .about-text {
    flex-direction: column;
    font-family: ATypewriterForMe;
    font-weight: 500;
    font-size: 1.5em;
    width: 100%;
    align-items: center;
}

.about-photo {
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.products-photo {
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: black;
    top: 0;
    right: 0;
    opacity: 0;
    transition: 0.2s cubic-bezier(0.4, 0, 1, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 4em;
    color: white;
    font-family: Homework;
    text-align: center;
}

.photography:hover .overlay {
    opacity: 0.5;
}

.workshops:hover .overlay {
    opacity: 0.5;
}

.overlay {
    border-radius: 3px;
}

.contact-photo {
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.stripe {
    height: 180px;
    overflow: hidden;
    display: inline-block;

    img {
        height: 100%;
        border-radius: 0px;
    }
}

.mobile-label {
    display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .mobile-label {
        display: block;
    }

    .stripe {
        display: none !important;
    }
}
