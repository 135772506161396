@import "../../assets/styles/content";

.text-left { align-items: flex-start;}

.filters {
    padding:15px;
    .col {
        display: flex;
        justify-content: center;
        > div.button {
            font-family: ATypewriterForMe;
            background-color: white;
            border: none;
            padding: 0 10px;
            margin: 5px;
            color: black;
            font-size: 15px;
            cursor: pointer;

            &.selected { border-bottom: 1px solid #000; }
            
            &:hover { border-bottom: 1px solid #000; }
        }
    }
}

.courses {
    display: none;
    transition: display 1s linear;

    &.show {
        display: flex !important;
        transition: display 1s linear;
    }
    .col {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 25px;

        > img { border-radius: 3px;}

        .item-badge { display: inline; margin: 5px}
    }

    > .col:hover {
        box-shadow: 0px 0px 8px 1px #e0e0e0;
        border-radius: 5px;
    }

    .item-detail { padding: 20px; }
    .col span { display: block;}
    .item-title { font-weight: 500; }
    .item-price { font-style: italic; text-align: right;}
    button { float: right; margin: 10px; }
}