footer {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-top: 1px solid #eee;
}

footer .logo{
    align-self: center;
    height: 50px;
}

#social {
    align-self: center;
    padding: 15px 0;
}

.fb-color {
    color: #3b5998;
}

.instagram-color {
    color: #8134af;
}

.mail-color {
    color: #0489c9;
}

ul, li {
    list-style: none;
    display: inline;
    padding: 10px;
}

ul li > a {
    text-decoration: none;
    list-style: none;
    color: black;
    text-transform: uppercase;
}