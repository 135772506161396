.divider {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px 20px 30px;

    &::before {
        margin-right: .25em;
    }

    &:before, :after {
        content: '';
        width: 65%;
        position: absolute;
        border-top: 1px solid #e0e0e0;
        transform: translatey(-50%);
    }

    ::after {
        margin-left: .25em;
    }
    
    img {
        margin: 15px;
        background: #fff;
        z-index: 9;
        box-shadow: 0px 0px 0px 0px white, 0px 1px 0px 0px white, 12px 0 0px 0px white, -12px 0 0px 0px white;
    }
}