@import "../../assets/styles/content";

#gallery-with-links {
    img {
        width: 100%;
        height: auto;
    }
}

#SRLLightbox {
    background-color: rgba(255,255,255,1);

    .SRLStage button { background-color: black !important;}
    .SRLStage button div svg path { fill: rgba(255,255,255,1);}
}
.SRLControls button { 
    background-color: black !important;

    div svg path { fill: rgba(255,255,255,1);}
}