@font-face {
    font-family: Homework;
    src: url(../fonts/Homework/Homework.otf);
}

.text {
    font-family: ATypewriterForMe;
    font-weight: 500;
    font-size: 1em;
}

.align-left {
    align-items: flex-start !important;
}

a {
    text-decoration: none;
    color: black;
}

.social {
    margin: 5px;
    height: 40px;
}

.social .col {
    height: 45px;
}

.social a {
    font-size: 30px;
    height: 40px;
    transition: font-size ease-in-out 0.2s;
}

.social a:hover {
    font-size: 35px;
}