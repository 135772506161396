body {
  margin: 0;
  background: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  font-size: 26px;
  font-weight: 800;
  /* animation: slideInDown;
  animation-duration: 1s; */
}

.section {
  /* animation: pulse;
  animation-duration: 1s; */
}

.content {
  margin-top: 80px;
}

img {
  border-radius: 3px;
}

.fb-color {
  color: #3b5998;
}

.instagram-color {
  color: #8134af;
}

.mail-color {
  color: #0489c9;
}

.phone-color {
  color: #3cc459;
}

.whatsapp-color {
  color: #25D366;
}

// CONTENT
@import "./assets/styles/content";
